import { List } from '@mui/material/';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SidebarItem from './sidebarItem/SidebarItem';

interface Props {
  items?: { [key: string]: string };
  sidebarComponent?: React.ReactNode;
}

// items hold value and label.
const Sidebar: React.FC<Props> = ({ items, sidebarComponent }) => {
  const [activeSideItem, setActiveSideItem] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!items) return;
    Object.values(items).forEach((value, index) => {
      if (location?.pathname.includes(value)) setActiveSideItem(index);
    });
  }, [location, location?.pathname, items]);

  return (
    <div style={{ backgroundColor: 'white', position: 'sticky', top: '40px' }}>
      {sidebarComponent}
      {items ? (
        <List dense className="list">
          {Object.keys(items).map((key, i) => (
            <SidebarItem
              key={`sidebar-item-${key}`}
              id={i}
              name={key}
              active={activeSideItem}
              onClick={() => {
                setActiveSideItem(i);
                navigate(items[key]);
              }}
            />
          ))}
        </List>
      ) : null}
    </div>
  );
};

export default Sidebar;
