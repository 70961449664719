import React from 'react';

import { Typography } from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';
import Footer from '../../components/footer/Footer';
import Content from '/components/content/Content';
import Jumbotron from '/components/jumbotron/Jumbotron';

const Home: React.FC = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <Jumbotron />
      <Content>
        <Typography variant="h5" style={isMobile ? { marginTop: '16px' } : {}}>
          Hej and välkommen!
        </Typography>
        <p>
          We at the THS International Reception want to give you the warmest welcome to the International Reception.
        </p>
        <ul>
          <li>
            {' '}
            <b>Step 1.</b> Create an account by clicking the “Register” button at the top of this page.{' '}
          </li>
          <li>
            {' '}
            <b>Step 2.</b> Fill in your information on this aforementioned page.{' '}
          </li>
          <li>
            {' '}
            <b>Step 3.</b> Get familiar with the system.{' '}
          </li>
          <li>
            {' '}
            <b>Step 4.</b> Once the booking period opens, sign up for the events you want to help out at.{' '}
          </li>
          <li>
            {' '}
            <b>Step 5.</b> Attend the JML training in January. You will not be able to attend any event if you do not have completed it.{' '}
          </li>
          <li>
            {' '}
            <b>Step 6.</b> Receive more info from the Event Responsibles 1-2 days before the event on Slack.{' '}
          </li>
          <li>
            {' '}
            <b>Step 7.</b> Attend the event!{' '}
          </li>
        </ul>
        <p>
          Besides attending the events you signed up for, you are{' '} <b> asked to carry out three</b> buddy meetings. 
          Specific days are reserved for those to have most of the students free to attend. 
          You will soon receive your Buddy group by email and will be able to contact the students in your group soon after 
          {' '} <b>(remember to BCC)</b>.
        </p>
        <p>
          If you have any questions, please check out the{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1065HnaFlgmDEnDVL5QrgEZms-DY9BGqB/view?usp=sharing">
            buddy handbook
          </a>{' '}
          or to contact the HR manager on Slack.
        </p>
        <p>The Project Team of the THS International Reception</p>
      </Content>

      <Footer />
    </>
  );
};

export default Home;
