import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

export const DEFAULT_PROJECT = 7;
export const DEFAULT_ROUTE = `/${DEFAULT_PROJECT}/`;

/**
 * Renders page depending on if the project exists or not
 * To prevent wrong URLs.
 */
const ProjectRender: React.FC<Props> = ({ children }) => {
  const permittedIds = ['2', '3', '4', '5', '6', '7', '8'];
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [somethingWrong, setSomethingWrong] = useState(false);

  useEffect(() => {
    if (!permittedIds.includes(projectId || '')) {
      setSomethingWrong(true);
      navigate(DEFAULT_ROUTE);
      navigate(0); // force refresh
    }
  }, [projectId]);

  return <>{!somethingWrong && children}</>;
};

export default ProjectRender;
