import React, { createContext, useMemo, useState } from 'react';

interface SubMenuContextProps {
  isSubMenu: boolean;
  openSubMenu: () => void;
  closeSubMenu: () => void;
}

const defaultSubMenuContext: SubMenuContextProps = {
  isSubMenu: false,
  openSubMenu: () => {},
  closeSubMenu: () => {},
};

export const SubMenuContext = createContext(defaultSubMenuContext);

export const SubMenuProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSubMenu, setIsSubMenu] = useState(false);

  const openSubMenu = () => {
    setIsSubMenu(true);
    window.scrollTo(0, 0);
  };

  const closeSubMenu = () => {
    setIsSubMenu(false);
  };

  const value = useMemo(
    () => ({
      isSubMenu,
      openSubMenu,
      closeSubMenu,
    }),
    [isSubMenu]
  );

  return <SubMenuContext.Provider value={value}>{children}</SubMenuContext.Provider>;
};
