import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import useLoggedIn from 'hooks/useLoggedIn';
import useProjectId from 'hooks/useProjectId';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import pic from '/static/start1.webp';

const ImageContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  backgroundImage: `url(${pic})`,
  height: '530px',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50% 50%',
  [theme.breakpoints.down('md')]: {
    height: '480px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '430px',
  },
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  margin: '0 auto',
  width: '297px',
  marginTop: '456px',
  [theme.breakpoints.down('md')]: {
    marginTop: '383px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '248px',
    marginTop: '295px',
  },
}));

const RegisterButton = styled(Button)(({ theme }) => ({
  width: '97px',
  marginRight: '51px',
  [theme.breakpoints.down('sm')]: {
    marginRight: '27px',
  },
}));

const LoginButton = styled(Button)(({ theme }) => ({
  width: '97px',
  marginLeft: '51px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '27px',
  },
}));

const Jumbotron: React.FC = () => {
  const navigate = useNavigate();
  const projectId = useProjectId();
  const loggedIn = useLoggedIn();

  return (
    <ImageContainer>
      <ButtonContainer>
        {loggedIn ? (
          <>
            <RegisterButton
              color="secondary"
              variant="contained"
              onClick={() => navigate(`/${projectId}/createaccount`)}
            >
              Register
            </RegisterButton>
            <LoginButton color="secondary" variant="contained" onClick={() => navigate(`/${projectId}/login`)}>
              Login
            </LoginButton>
          </>
        ) : null}
      </ButtonContainer>
    </ImageContainer>
  );
};

export default Jumbotron;
