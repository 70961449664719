import React, { createContext, useMemo, useState } from 'react';

interface FeedbackContextProps {
  // ... variables and available methods
  open: boolean;
  text: string;
  severity: 'error' | 'success';
  openSuccessFeedback: (text: string) => void;
  openErrorFeedback: (text: string) => void;
  closeFeedback: () => void;
}

const defaultFeedbackContext: FeedbackContextProps = {
  open: false,
  text: '',
  severity: 'error',
  openErrorFeedback: () => {},
  openSuccessFeedback: () => {},
  closeFeedback: () => {},
};

export const FeedbackContext = createContext(defaultFeedbackContext);

export const FeedbackProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const [severity, setSeverity] = useState<'error' | 'success'>('error');

  const openSuccessFeedback = (toastText: string) => {
    setOpen(true);
    setText(toastText);
    setSeverity('success');
  };

  const openErrorFeedback = (toastText: string) => {
    setOpen(true);
    setText(toastText);
    setSeverity('error');
  };

  const closeFeedback = () => {
    setOpen(false);
  };

  // implement methods, variables
  const value = useMemo(
    () => ({
      open,
      text,
      severity,
      openSuccessFeedback,
      openErrorFeedback,
      closeFeedback,
    }),
    [open, text, severity]
  );

  return <FeedbackContext.Provider value={value}>{children}</FeedbackContext.Provider>;
};
