import React from 'react';
import { Divider } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

const ContentHeader: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div style={{ marginTop: '16px' }}>
        {children}
        <Divider style={{ marginTop: '16px' }} />
      </div>
    </>
  );
};

export default ContentHeader;
