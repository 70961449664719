import React, { Suspense, lazy } from 'react';

import Typography from '@mui/material/Typography';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import Snackbar from 'components/snackBar/Snackbar';
import AppContent from '/components/appContent/AppContent';

import ProjectRender, { DEFAULT_ROUTE } from 'containers/projectRender/ProjectRender';
import { FeedbackProvider } from 'context/FeedbackContext';
import { SubMenuProvider } from 'context/SubMenuContext';
import RedirectPage from 'pages/root/ReplacePage';
import RootPage from 'pages/root/RootPage';
import theme from '/MuiTheme';
import PrivateRoute from '/components/privateRoute/PrivateRoute';
import Navbar from '/containers/navbar/Navbar';

const CreateAccount = lazy(() => import('/pages/auth/createAccount/CreateAccount'));
const LoginReset = lazy(() => import('/pages/auth/loginReset/LoginReset'));
const Login = lazy(() => import('/pages/auth/login/Login'));
const Events = lazy(() => import('/pages/events/Events'));
const Admin = lazy(() => import('/pages/admin/Admin'));
const Tickets = lazy(() => import('/pages/tickets/Tickets'));
const SentForgot = lazy(() => import('/pages/auth/sentForgot/SentForgot'));
const ConfirmEmail = lazy(() => import('/pages/auth/confirmEmail/ConfirmEmail'));
const LoginForgot = lazy(() => import('/pages/auth/loginForgot/LoginForgot'));
const Logout = lazy(() => import('/pages/auth/logout/LogoutPage'));
const WriteProjectInfo = lazy(() => import('/pages/writeProjectInfo/WriteProjectInfo'));
const Profile = lazy(() => import('/pages/profile/Profile'));
const About = lazy(() => import('/pages/about/About'));
const Pony = lazy(() => import('/pages/pony/Pony'));
const PatchEmail = lazy(() => import('/pages/auth/patchEmail/PatchEmail'));

const App: React.FC = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <FeedbackProvider>
          <SubMenuProvider>
            <Typography component="section">
              <AppContent>
                <Router>
                  <Snackbar />
                  <Navbar />
                  <Routes>
                    <Route
                      path="/:projectId/*"
                      element={
                        <ProjectRender>
                          <Suspense fallback={<CircularProgress />}>
                            <Routes>
                              <Route path="/login/*" element={<Login />} />
                              <Route
                                path="/events/*"
                                element={
                                  <PrivateRoute>
                                    <Events />
                                  </PrivateRoute>
                                }
                              />
                              <Route
                                path="/tickets/*"
                                element={
                                  <PrivateRoute>
                                    <Tickets />
                                  </PrivateRoute>
                                }
                              />
                              <Route
                                path="/admin/*"
                                element={
                                  <PrivateRoute admin>
                                    <Admin />
                                  </PrivateRoute>
                                }
                              />
                              <Route
                                path="/profile/*"
                                element={
                                  <PrivateRoute>
                                    <Profile />
                                  </PrivateRoute>
                                }
                              />
                              <Route
                                path="/logout/*"
                                element={
                                  <PrivateRoute>
                                    <Logout />
                                  </PrivateRoute>
                                }
                              />
                              <Route path="/forgot/*" element={<LoginForgot />} />
                              <Route path="/reset/*" element={<LoginReset />} />
                              <Route path="/createAccount/*" element={<CreateAccount />} />
                              <Route path="/sentForgot/*" element={<SentForgot />} />
                              <Route path="/confirm/*" element={<ConfirmEmail />} />
                              <Route path="/patchEmail/*" element={<PatchEmail />} />
                              <Route path="/pony/*" element={<Pony />} />
                              <Route path="/projectInfo/*" element={<WriteProjectInfo />} />
                              <Route path="about/*" element={<About />} />
                              <Route path="/" element={<RootPage />} />
                              <Route path="*" element={<RedirectPage />} />
                            </Routes>
                          </Suspense>
                        </ProjectRender>
                      }
                    />
                    <Route path="*" element={<Navigate to={DEFAULT_ROUTE} replace />} />
                  </Routes>
                </Router>
              </AppContent>
            </Typography>
          </SubMenuProvider>
        </FeedbackProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
