import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import useLoggedIn from 'hooks/useLoggedIn';
import useProjectId from 'hooks/useProjectId';
import Home from 'pages/home/Home';
import { userInProject } from 'queries/user/user-in-project';
import React from 'react';
import { Navigate } from 'react-router-dom';

const RootPage: React.FC = () => {
  const loggedIn = useLoggedIn();
  const projectId = useProjectId();
  const inProject = useQuery(userInProject({ projectId, loggedIn }));

  if (!loggedIn) return <Home />;
  if (inProject.isLoading || !inProject.data) return <CircularProgress />;
  return <>{!inProject.data?.isSigned ? <Navigate to={`/${projectId}/projectInfo`} replace /> : <Home />}</>;
};

export default RootPage;
