import { List, SwipeableDrawer, Typography, styled } from '@mui/material';
import React from 'react';
import logo from '/static/logo_500.webp';

const Head = styled('div')(({ theme }) => ({
  width: '224px',
  paddingTop: theme.spacing(8),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(6),
  paddingBottom: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.primary.main}${theme.palette.overlay.opacity.selected}`,
}));

const MenuList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(2),
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.surface.text}${theme.palette.other.opacityDisabled}`,
  marginTop: '0.5rem',
}));

interface NavDrawerProps {
  open: boolean;
  children: React.ReactNode;
  toggleDrawer: () => void;
}

const NavDrawer: React.FC<NavDrawerProps> = ({ open, children, toggleDrawer }) => {
  return (
    <SwipeableDrawer
      disableBackdropTransition
      sx={{ width: '264px' }}
      open={open}
      onClick={toggleDrawer}
      onOpen={toggleDrawer}
      onClose={toggleDrawer}
    >
      <Head>
        <img src={logo} alt="ths-logo" style={{ height: '100px' }} />
        <Typography variant="h6">THS International Reception Spring 2024</Typography>
        <Subtitle variant="subtitle2">Event Signup</Subtitle>
      </Head>
      <MenuList>{children}</MenuList>
    </SwipeableDrawer>
  );
};

export default NavDrawer;
