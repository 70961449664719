import React, { useContext, useEffect } from 'react';

import Alert from '@mui/material/Alert';
import MuiSnackbar from '@mui/material/Snackbar';
import { useQueryClient } from '@tanstack/react-query';
import { FeedbackContext } from 'context/FeedbackContext';
import useProjectId from 'hooks/useProjectId';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorResponse } from 'types/types';

const INVALID_ERROR_MESSAGE = 'Invalid server error';
const UNKNOWN_ERROR_MESSAGE = 'Unknown error';

const Snackbar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const projectId = useProjectId();
  const feedbackContext = useContext(FeedbackContext);

  const onError = (err: unknown) => {
    const convertedErr = err as ErrorResponse;

    if (convertedErr.response?.status === 401 && location.pathname !== `/${projectId}/login`) {
      feedbackContext.openErrorFeedback('Your session has expired, please log in again.');
      localStorage.removeItem('token');
      navigate(`/${projectId}/login`);
    } else if (convertedErr.response?.status === 500) {
      feedbackContext.openErrorFeedback(INVALID_ERROR_MESSAGE);
    } else {
      feedbackContext.openErrorFeedback(convertedErr.response?.data?.error?.message || UNKNOWN_ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    queryClient.getQueryCache().config.onError = onError;
    queryClient.getMutationCache().config.onError = onError;

    return () => {
      queryClient.getQueryCache().config.onError = () => {};
      queryClient.getMutationCache().config.onError = () => {};
    };
  }, [queryClient]);

  return (
    <MuiSnackbar open={feedbackContext.open} onClose={feedbackContext.closeFeedback}>
      <Alert elevation={6} severity={feedbackContext.severity as any} onClose={feedbackContext.closeFeedback}>
        {feedbackContext.text}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
